<template>
    <div class="realisation-content">
        <nav-menu></nav-menu>
        <div class="content">
            <div v-if="project" class="flex">
                <div class="project">
                    <h1>{{ project.title }}</h1>
                    <p class="technologies">{{ project.technologies.join(', ') }}</p>
                    <p v-html="formattedDescription" class="description"></p>
                    <div class="buttons">
                        <router-link to="/realisations" class="button gray">
                            Retour
                        </router-link>
                        <a v-if="project.url" :href="`${project.url}`" target="_blank" class="button">Visiter</a>
                    </div>
                </div>
                <div class="image">
                    <img :src="require(`@/assets/realisations/mockups/${project.image}.png`)" :alt="project.title">
                </div>
            </div>
            <h1 v-else>Projet non trouvé</h1>
        </div>
    </div>
</template>

<script>
import NavMenu from '../components/Nav-menu';
import projectsData from '../data/realisationsContent.json';

export default {
  name: 'RealisationContent',
  components: {
    'nav-menu': NavMenu,
  },
  data() {
    return {
      project: null
    };
  },
  computed: {
    formattedDescription() {
      return this.project ? this.project.description.replace(/\n/g, '<br>') : '';
    }
  },
  mounted() {
    const slug = this.$route.params.slug;
    this.project = projectsData.find(p => p.slug === slug);
  },
};
</script>

<style lang="scss" scoped>
@import '../scss/components/realisation_content.scss';
</style>