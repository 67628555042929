<template>
  <div class='realisations-content'>
    <div class="real large-container">
      <ul class="navbar reveal">
      </ul>
      <div class="real-header">
        <div id="mountain-rea" class="reveal"></div>
        <h1 id="medium-title-realisations" class="medium-title reveal">Réalisations</h1>
      </div>
    </div>
    <div class="large-container">
      <teahu></teahu>
      <voicy></voicy>
      <onboard-me></onboard-me>
      <brigade></brigade>
      <rick-and-morty></rick-and-morty>
      <comon></comon>
      <!-- <ckoa></ckoa> -->
      <!-- <weather></weather> -->
      <!-- <biere></biere> -->
    </div>
  </div>
</template>


<script>
import Teahu from './realisations/Teahu'
import Voicy from './realisations/Voicy'
import OnboardMe from './realisations/Onboard-me'
import Brigade from './realisations/Brigade'
import RickAndMorty from './realisations/RickAndMorty.vue'
import Comon from './realisations/Comon'
// import Ckoa from './realisations/Ckoa'
// import Weather from './realisations/Weather'
// import Biere from './realisations/Biere'

  export default {
    name: 'RealisationsContent',
    components: {
      'teahu': Teahu,
      'voicy': Voicy,
      'onboard-me': OnboardMe,
      'brigade': Brigade,
      'rick-and-morty': RickAndMorty,
      'comon': Comon,
      // 'ckoa': Ckoa,
      // 'weather': Weather,
      // 'biere': Biere
    },
    mounted: function() {
      
    }
  }

</script>

<style lang="scss">
@import '../scss/components/realisations_content.scss';
@import '../scss/components/realisations_animations.scss';

</style>