<template>
  <div class="real real5">
    <ul class="navbar reveal"></ul>
    <div class="real-infos">
      <h1 class="medium-title medium reveal">Rick and Morty</h1>
      <div class="rea-content">
        <div>
          <div class="line-rea"></div>
          <div class="description-rea reveal">Site web</div>
          <router-link to="/realisations/rickandmorty" class="button">
            Voir le projet
          </router-link>
        </div>
        <router-link to="/realisations/rickandmorty" class="img-rea reveal">
        </router-link>
      </div>
      <div class="primary-title reveal">05</div>
    </div>
  </div>
</template>


<script>


  export default {
    name:'RickAndMorty',
  }

</script>

<style lang="scss">

</style>